<div class="alarm_screen" style="box-shadow: 1px 3px 1px #80808024">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform: capitalize">
        {{ action == "update" ? "Update" : "Add" }} S3
      </h4>
    </div>

    <div class="overflow-auto">

          <!-- Row for MID -->
          <div class="row">
            <label>Account Id</label>
            <input type="text" class="form-control" [(ngModel)]="ri.mid" disabled>
          </div>

           <!-- Row for Customer Name -->
      <div class="row">
        <label>Customer Name</label>
        <input type="text" class="form-control" [(ngModel)]="ri.customerName">
      </div>

            <!-- Row for Customer ID -->
            <div class="row">
              <label>Customer ID</label>
              <input type="text" class="form-control" [(ngModel)]="ri.customerId">
            </div>

                  <!-- Row for ERP Order Number -->
      <div class="row">
        <label>ERP Order Number</label>
        <input type="text" class="form-control" [(ngModel)]="ri.erpOrderNumber">
      </div>
      
      <!-- Row for Currency Code -->
      <div class="row">
        <label>Currency Code</label>
        <input type="text" class="form-control" [(ngModel)]="ri.currencyCode">
      </div>    
      
    </div>
    
    

    <!-- Action Buttons -->
    <div class="alarm_actions">
      <div style="display: flex; justify-content: flex-end">
        <button class="btn" (click)="updateEntry()" style="
            background-color: #5864ff;
            color: white;
            text-transform: capitalize;
            margin-right: 20px;
          ">
          {{ action == "update" ? "Update" : "Add" }}
        </button>
        <button class="btn btn-light" (click)="close(false)">Cancel</button>
      </div>
    </div>
  </div>

  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>
</div>