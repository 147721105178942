import { Component, OnDestroy, OnInit, NgZone, Input } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import * as moment from 'moment';

declare var $: any;
declare let window: any;

@Component({
  templateUrl: './master-job.component.html',
  styleUrls: ['./master-job.component.css'],
})
export class SingleMasterJob implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  masterAccounts: any = [];
  fetchAccountID: string;
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  discount: string;
  dateRange: string;
  isRunning: boolean = false;
  accountId: any;
  regionId: any;
  currentMessage: any;
  otp_popup: boolean = false;
  otp: any;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    let temp = moment();
    $('#currentMonth').val(
      this.dateRange || `${temp.format('MM')}-${temp.format('YYYY')}`
    );
    $('#currentMonth')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm-yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });
    let nextMonth = moment().add(1, 'months');
    $('#nextMonth').val(
      `${nextMonth.format('MM')}-${nextMonth.format('YYYY')}`
    );
    $('#nextMonth')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm-yyyy',
        orientation: 'bottom auto',
        endDate: moment().add(1, 'year').toDate(), // Assuming next month picker should allow dates up to one year in the future
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
    this.status();
  }
  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
  // async run() {
  //   this.notifier.loading(true);
  //   let data = {
  //     action: 'run',
  //     masterAccountId: this.accountId,
  //     nextMonthYear: $('#nextMonth').val(),
  //     currentMonthYear: $('#currentMonth').val(),
  //   };
  //   let header = {
  //     Authorization: localStorage.getItem('t'),
  //   };
  //   let apiURL = `https://api.swayam.cloud/v3/admin/billing/single-master-job`;
  //   let result = await this.apiService.postDataPromis(apiURL, data, header);
  //   this.isRunning = true;
  //   this.notifier.alert('Info', '', result.msg, 'info', 5000);

  //   this.notifier.loading(false);
  // }

  cancelOtp(): void {
    this.otp_popup = false; // Close the popup
    this.otp = ''; // Reset the OTP
  }

  async requestOtp(){
    this.notifier.loading(true);

    let data = {
      action:"sendOTP"
    }
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/single-master-job`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.S == '1' || result.s == '1') {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.otp_popup = true;
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);

  }

  async validateOtp(){
    this.notifier.loading(true);

    let data = {
      masterAccountId: this.accountId,
      nextMonthYear: $('#nextMonth').val(),
      currentMonthYear: $('#currentMonth').val(),
      action:"validateOTP",
      otp:this.otp,
    }
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/single-master-job`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.S == '1' || result.s == '1') {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.otp_popup = false;
      this.status();
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);

  }



  async status() {
    this.notifier.loading(true);
    let data = {
      action: 'status',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/single-master-job`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.s == '2') {
      this.isRunning = true;
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    } else {
      this.isRunning = false;
    }
    this.notifier.loading(false);
  }

  async load() {
    this.notifier.loading(true);
    await this.loadMasterAccounts();
    this.notifier.loading(false);
  }

  async loadMasterAccounts() {
    this.fetchAccountID = this.accountId;
    let data = {
      lid: [this.accountId],
      userType: 'C',
      action: 'fetchMasterAccList',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.masterAccounts = result.masterAccList;
      if (this.masterAccounts && this.masterAccounts.length > 0) {
        this.accountId = this.masterAccounts[0];
      } else {
        this.masterAccounts = [];
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
  }

  callFunction(name: any, param: any) {}
}
