import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { of, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';
declare let $: any;
declare let flatpickr: any;

import * as moment from 'moment';

@Component({
  selector: 'mrr-update',
  templateUrl: './update.mrr.component.html',
  styleUrls: [
    '../../../../views/cbs/enableri/update/update.enableri.component.css',
  ],
})
export class MRRUpdateComponent implements OnInit, OnDestroy {
  selectedServiceNames: any[] = [];
  isAllSelect: boolean = false;
  serviceNamelist: any = [];
  currentMessage: any;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  date: any;
  @Output() hideModel = new EventEmitter<any>();
  @Input('action') action: any;
  @Input() mrr: any;
  @Input() mrrDatasub: any;
  @Input() unsaved: any;
  @Input() mrrData: any;
  @Input() mid: any;
  @Input() subEc2: any;
  @Input() subRds: any;
  @Input() s3Mrr: any;
  @Input() cfrcMrr: any;
  @Input() s3Keys: any;
  @Input() cfrcKeys: any;

  serviceData: any = [];

  dateRange: any;

  masterAccounts: any[];
  unselectedService: any[];
  comment: '';
  linkedAccounts: any;
  accounts: any;
  childs: any;
  selectedClientName: any = localStorage.getItem('selectedClientName');
  showS3DiscountFields: boolean;
  showRDSDiscountFields: boolean;
  showCloudFrontDiscountFields: boolean;
  showEC2DiscountFields: boolean = false;
  popupData: any[] = [];
  lek: any;
  service_Name: any;
  showPopup: boolean = false;
  processing: boolean = false;
  headers_pop: { id: string; name: string }[];
  selectedService: any;
  riApplied: any;
  odApplied: any;
  spotApplied: any;
  ebsapplied: any;
  othersapplied: any;
  isModalVisible: boolean;
  allCallsCompleted: boolean = false;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    console.log('mrrdatasub', this.mrrDatasub);
    this.mrr = { ...this.mrr };
    this.accountId = localStorage.getItem('accountId');
    this.linkedAccounts = JSON.parse(
      localStorage.getItem('accountIdData') || '{}'
    );

    if (Array.isArray(this.linkedAccounts.list)) {
      this.accounts = this.linkedAccounts.list.map(
        (accountObj: any) => accountObj.account
      );
    } else {
      console.error(
        'linkedAccounts does not have a valid list key or it is not an array.'
      );
    }
    setTimeout(() => {
      $('#linkedAccounts').selectpicker('refresh');
    }, 100);
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      }
    });
    flatpickr('#dataRangePicker', {
      dateFormat: 'Y-m-d',
      mode: 'range',
      showMonths: 2,
      defaultDate: this.dateRange ? this.dateRange.split(' to ') : null,
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        if (!dateStr.includes('to')) return;
        this.dateRange = dateStr;
      },
    });
    if (this.action == 'update') {
      this.dateRange = this.mrr.startDate + ' to ' + this.mrr.endDate;
    }

    this.date = moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('MM-YYYY');
    // setTimeout(()=>{
    // this.serviceNames();

    // }, 10);
  }

  load() {}

  isServiceDisabled(serviceName: string): boolean {
    for (let service of this.mrrData) {
      if (service.serviceName === serviceName) {
        return true;
      }
    }
    return false;
  }

  selectAllchilds(event: any) {
    const isChecked = event.target.checked;
    if (isChecked) {
      // Select all accounts
      this.childs = this.linkedAccounts['list'].map(
        (account: any) => account.accountId
      );
      this.serviceNames(this.childs);
    } else {
      // Deselect all accounts
      this.childs = [];
    }
    setTimeout(() => {
      $('#linkedAccounts').selectpicker('refresh');
    }, 10);
  }

//   processItemsSequentially() {
//   if(this.action == 'add'){
//    // Example array of services (you can replace this with your actual data)
//   this.services =$('#serviceNameId').val(); // Your list of services or data
//   const services = this.services;
//   console.log("services",services)
//   from(services).pipe(
//     concatMap(service => {
//       return from(this.updateEntry(service)); // Call updateEntry for each item
//     })
//   ).subscribe({
//     next: () => {
//       console.log('Service updated successfully');
//     },
//     error: (err) => {
//       console.error('Error occurred during sequential updates:', err);
//     },
//     complete: () => {
//       console.log('All services processed');
//     }
//   });
// }

// else{
//   this.updateEntry();
// }
//   }


  services:any;
  // async updateEntry(service?:any): Promise<any> {
  //   this.notifier.loading(true);
  //   let data: any;
  //   const item = this.services.find((s:any) => s.name === service.name);
  //   try {
  //   if (item) {
  //     // Mark the item as loading
  //     item.isLoading = true;
  //     item.isSuccess = false;
  //     item.isError = false;


  //   if (this.action.trim() == 'add') {
  //     if (this.dateRange == '' || this.dateRange == undefined) {
  //       this.notifier.alert(
  //         'Info',
  //         '',
  //         'Please select date range',
  //         'info',
  //         500
  //       );
  //       return;
  //     }
  //     const start = this.dateRange.split('to')[0].trim();
  //     const end = this.dateRange.split('to')[1].trim();
  //     let serviceNames = service;

  //     for (let i = 0; i < serviceNames.length; i++) {
  //       this.selectedServiceNames.push({
  //         serviceName: serviceNames[i],
  //         mrrDiscount: this.mrr.mrrDiscount,
  //         comment: this.mrr.comment,
  //         startDate: start,
  //         endDate: end,
  //       });
  //     }
  //     this.notSelectedService();

  //     if (this.selectedClientName === 'NHA') {
  //       data = {
  //         a: 'add',
  //         childs: this.childs,
  //         mrrList: this.selectedServiceNames,
  //         sub: {
  //           instance: this.mrrDatasub.instance,
  //           ebs: this.mrrDatasub.ebs,
  //           others: this.mrrDatasub.others,
  //         },
  //         mid: this.mid,
  //       };
  //     } else {
  //       data = {
  //         a: 'add',
  //         childs: this.childs,
  //         mrrList: this.selectedServiceNames,
  //         subEc2: this.subEc2,
  //         subRds: this.subRds,
  //         s3Mrr: this.s3Mrr,
  //         cfrcMrr: this.cfrcMrr,
  //         mid: this.mid,
  //       };
  //     }
  //   } else {
  //     const start = this.mrr.startDate;
  //     const end = this.mrr.endDate;
  //     const serviceName = this.mrr.serviceName?.trim();

  //     if (this.selectedClientName === 'NHA') {
  //       data = {
  //         lid: this.accountId,
  //         a: 'update',
  //         serviceName: this.mrr.serviceName,
  //         mrrDiscount: this.mrr.mrrDiscount,
  //         startDate: this.mrr.startDate,
  //         endDate: this.mrr.endDate,
  //         comment: this.mrr.comment,
  //         sub: {
  //           instance: this.mrrDatasub.instance,
  //           ebs: this.mrrDatasub.ebs,
  //           others: this.mrrDatasub.others,
  //         },
  //         mid: this.mid,
  //       };
  //     } else {
  //       if (serviceName === 'Amazon Relational Database Service') {
  //         data = {
  //           a: 'update',
  //           lid: this.accountId,
  //           serviceName: this.mrr.serviceName,
  //           mrrDiscount: this.mrr.mrrDiscount,
  //           startDate: this.mrr.startDate,
  //           endDate: this.mrr.endDate,
  //           subRds: {
  //             instance: this.mrrDatasub.instance,
  //             others: this.mrrDatasub.others,
  //           },
  //           mid: this.mid,
  //         };
  //       } else if (serviceName === 'Amazon Elastic Compute Cloud') {
  //         data = {
  //           a: 'update',
  //           lid: this.accountId,
  //           serviceName: this.mrr.serviceName,
  //           mrrDiscount: this.mrr.mrrDiscount,
  //           startDate: this.mrr.startDate,
  //           endDate: this.mrr.endDate,
  //           subEc2: {
  //             instance: this.mrrDatasub.instance,
  //             ebs: this.mrrDatasub.ebs,
  //             others: this.mrrDatasub.others,
  //           },
  //           mid: this.mid,
  //         };
  //       } else if (serviceName === 'Amazon Simple Storage Service') {
  //         data = {
  //           a: 'update',
  //           lid: this.accountId,
  //           serviceName: this.mrr.serviceName,
  //           mrrDiscount: this.mrr.mrrDiscount,
  //           startDate: this.mrr.startDate,
  //           endDate: this.mrr.endDate,
  //           s3Mrr: this.mrrDatasub.s3Mrr,
  //           mid: this.mid,
  //         };
  //       } else if (serviceName === 'Amazon CloudFront') {
  //         data = {
  //           a: 'update',
  //           lid: this.accountId,
  //           serviceName: this.mrr.serviceName,
  //           mrrDiscount: this.mrr.mrrDiscount,
  //           startDate: this.mrr.startDate,
  //           endDate: this.mrr.endDate,
  //           cfrcMrr: this.mrrDatasub.cfrc,
  //           mid: this.mid,
  //         };
  //       } else {
  //         data = {
  //           a: 'update',
  //           lid: this.accountId,
  //           serviceName: this.mrr.serviceName,
  //           mrrDiscount: this.mrr.mrrDiscount,
  //           startDate: this.mrr.startDate,
  //           endDate: this.mrr.endDate,
  //           mid: this.mid,
  //         };
  //       }
  //     }
  //   }
  // }
  //   console.log('data', data);
  //   let header = {
  //     Authorization: localStorage.getItem('t'),
  //   };
  //   let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/mrr`;
  //     // Replace the await with a promise-based API call inside the observable
  //     let result = await this.apiService.postDataPromis(apiURL, data, header);
  //     if (result.status == '1' || result.s == '1') {
  //       this.notifier.alert('Success', '', result.msg, 'success', 5000);
  //       item.isSuccess = true;
  //       this.close(true);
  //     } else {
  //       this.notifier.alert('Info', '', result.error, 'info', 5000);
  //       item.isError = true;
  //     }
  //   } catch (error) {
  //     this.notifier.alert('Error', '', 'Failed to call API', 'error', 5000);
  //     item.isError = true;
  //   } finally {
  //     item.isLoading = false;
  //     this.notifier.loading(false);
  //   }
  // }

  async updateEntry(child?: any): Promise<any> {
    // Initialize variables
    let data: any;
    let item;
  
    try {
        // Mark the item as loading
        // Prepare data based on action
          item = this.childs.find((s: any) => s.name === child.name); // Find `child` by name

          item.isLoading = true;
          item.isSuccess = false;
          item.isError = false;
          if (!this.dateRange) {
            this.notifier.alert('Info', '', 'Please select a date range', 'info', 500);
            return;
          }
  
          const [start, end] = this.dateRange.split('to').map((date: any) => date.trim());
          let serviceNames = $('#serviceNameId').val();

          for (let i = 0; i < serviceNames.length; i++) {
            this.selectedServiceNames.push({
              serviceName: serviceNames[i],
              mrrDiscount: this.mrr.mrrDiscount,
              comment: this.mrr.comment,
              startDate: start,
              endDate: end,
            });
          this.notSelectedService();
          // Pass child as lid directly to prepareAddData
          data = this.prepareAddData(child, start, end);
        } 
  
        // Log prepared data for debugging
        console.log('API Request Data:', data);
  
        // API call
        const header = { Authorization: localStorage.getItem('t') };
        const apiURL = `https://api.swayam.cloud/v3/admin/billing/mrr`;
        const result = await this.apiService.postDataPromis(apiURL, data, header);
  
        // Handle API response
        if (result.status == '1' || result.s == '1') {
          item.isSuccess = true;
          if(this.action !== 'add'){
            this.notifier.alert('Success', '', result.msg, 'success', 5000);
            this.close(true);
          }
        } else {
          item.isError = true;
          if(this.action !== 'add'){
            this.close(true);
            this.notifier.alert('Error', '', result.msg, 'error', 5000);

          }

        }
      
    } catch (error) {
      if(this.action == 'add'){
      item.isError = true;
      }
    } finally {
      if(this.action == 'add'){
      // Ensure loading state is cleared
      item.isLoading = false;

      }
    }

  }
  
  
  async processItemsSequentially(): Promise<void> {
    if ( this.action == 'add'){
      this.isModalVisible = true;

  
      // Assuming `this.childs` is already populated via ngModel
      this.childs = this.childs.map((child: any) => ({
        name: child,  // The child name (string)
        isLoading: true, // Initialize isLoading as true
        isSuccess: false, // Initialize isSuccess as false
        isError: false,    // Initialize isError as false
      }));
    
      console.log("this.childs", this.childs);
    
      for (const child of this.childs) {
        try {
          // Call updateEntry for each child in the array
          await this.updateEntry(child);
        } catch (error) {
          console.error('Error processing child:', child, error);
        }
      }
      this.allCallsCompleted = true;
    }
    else{
      this.prepareUpdateData()
    }
  }
  


  prepareAddData(child: any, start: string, end: string): any {
    if (this.selectedClientName === 'NHA') {
      return {
        a: 'add',
        childs: [child.name],  // Add childs directly
        mrrList: this.selectedServiceNames,
        sub: {
          instance: this.mrrDatasub.instance,
          ebs: this.mrrDatasub.ebs,
          others: this.mrrDatasub.others,
        },
        mid: this.mid,
      };
    } else {
      return {
        a: 'add',
        childs: [child.name],  // Add childs directly
        mrrList: this.selectedServiceNames,
        subEc2: this.subEc2,
        subRds: this.subRds,
        s3Mrr: this.s3Mrr,
        cfrcMrr: this.cfrcMrr,
        mid: this.mid,
      };
    }
  }
  
  


  async prepareUpdateData() {

      const start = this.mrr.startDate;
      const end = this.mrr.endDate;
      const serviceName = this.mrr.serviceName?.trim();
       let data ;

      if (this.selectedClientName === 'NHA') {
        data = {
          lid: this.accountId,
          a: 'update',
          serviceName: this.mrr.serviceName,
          mrrDiscount: this.mrr.mrrDiscount,
          startDate: this.mrr.startDate,
          endDate: this.mrr.endDate,
          comment: this.mrr.comment,
          sub: {
            instance: this.mrrDatasub.instance,
            ebs: this.mrrDatasub.ebs,
            others: this.mrrDatasub.others,
          },
          mid: this.mid,
        };
      } else {
        if (serviceName === 'Amazon Relational Database Service') {
          data = {
            a: 'update',
            lid: this.accountId,
            serviceName: this.mrr.serviceName,
            mrrDiscount: this.mrr.mrrDiscount,
            startDate: this.mrr.startDate,
            endDate: this.mrr.endDate,
            subRds: {
              instance: this.mrrDatasub.instance,
              others: this.mrrDatasub.others,
            },
            mid: this.mid,
          };
        } else if (serviceName === 'Amazon Elastic Compute Cloud') {
          data = {
            a: 'update',
            lid: this.accountId,
            serviceName: this.mrr.serviceName,
            mrrDiscount: this.mrr.mrrDiscount,
            startDate: this.mrr.startDate,
            endDate: this.mrr.endDate,
            subEc2: {
              instance: this.mrrDatasub.instance,
              ebs: this.mrrDatasub.ebs,
              others: this.mrrDatasub.others,
            },
            mid: this.mid,
          };
        } else if (serviceName === 'Amazon Simple Storage Service') {
          data = {
            a: 'update',
            lid: this.accountId,
            serviceName: this.mrr.serviceName,
            mrrDiscount: this.mrr.mrrDiscount,
            startDate: this.mrr.startDate,
            endDate: this.mrr.endDate,
            s3Mrr: this.mrrDatasub.s3Mrr,
            mid: this.mid,
          };
        } else if (serviceName === 'Amazon CloudFront') {
          data = {
            a: 'update',
            lid: this.accountId,
            serviceName: this.mrr.serviceName,
            mrrDiscount: this.mrr.mrrDiscount,
            startDate: this.mrr.startDate,
            endDate: this.mrr.endDate,
            cfrcMrr: this.mrrDatasub.cfrc,
            mid: this.mid,
          };
        } else {
          data = {
            a: 'update',
            lid: this.accountId,
            serviceName: this.mrr.serviceName,
            mrrDiscount: this.mrr.mrrDiscount,
            startDate: this.mrr.startDate,
            endDate: this.mrr.endDate,
            mid: this.mid,
          };
        }
      }
  
        const header = { Authorization: localStorage.getItem('t') };
        const apiURL = `https://api.swayam.cloud/v3/admin/billing/mrr`;
        const result = await this.apiService.postDataPromis(apiURL, data, header);
  
        // Handle API response
        if (result.status === '1' || result.s === '1') {
            this.notifier.alert('Success', '', result.msg, 'success', 5000);
            this.close(true);
          }
        else {
          this.notifier.alert('Error', '', result.msg, 'error', 5000);
            this.close(true);

          }

  }
  
  

  closeModal(): void {
    // Hide the modal when the close button is clicked
    this.isModalVisible = false;
    this.close(true);
  }

  resetPercentage(section: { isMrr: boolean; perc: number }): void {
    console.log('section', section);
    if (!section.isMrr) {
      section.perc = 0;
    }
  }

  onMrrDatasubUpdate(updatedData: any) {
    this.mrrDatasub = updatedData;
    console.log('Updated mrrDatasub:', this.mrrDatasub);
  }

  onDataChange(addData: any) {
    console.log(addData);
  }

  async setHeadersBasedOnService(serviceName: string) {
    // Define header configurations based on the service name
    if (serviceName === 'Amazon Elastic Compute Cloud') {
      this.headers_pop = [
        { id: 'instanceId', name: 'Instance ID' },

        { id: 'instanceName', name: 'Instance Name' },

        { id: 'instanceType', name: 'Instance Type' },
        { id: 'os', name: 'OS' },
        { id: 'region', name: 'Region' },
      ];
    } else if (serviceName === 'Amazon Relational Database Service') {
      this.headers_pop = [
        // { id: 'key', name: 'Key' },
        { id: 'region', name: 'Region' },
        { id: 'usageType', name: 'Usage Type' },
        { id: 'azType', name: 'Availability Zone Type' },
        { id: 'dbEngine', name: 'DB Engine' },
      ];
    } else if (serviceName === 'Amazon Simple Storage Service') {
      this.headers_pop = [
        // { id: 'key', name: 'Key' },
        { id: 'region', name: 'Region' },
        { id: 'usageType', name: 'Usage Type' },
      ];
    } else if (serviceName === 'Amazon CloudFront') {
      this.headers_pop = [
        // { id: 'key', name: 'Key' },
        { id: 'region', name: 'Region' },
        { id: 'usageType', name: 'Usage Type' },
      ];
    } else {
      // Default headers if the service name does not match
      this.headers_pop = [
        { id: 'serviceName', name: 'Service Name' },
        { id: 'mrrDiscount', name: 'MRR Discount' },
        { id: 'comment', name: 'Comment' },
      ];
    }
  }

  latestSelectedAccount: string | null = null;
  previousSelection: string[] = [];

  onAccountChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedOptions = Array.from(selectElement.selectedOptions).map(
      (option) => option.value
    );

    // Check if any new account is selected
    const newSelections = selectedOptions.filter(
      (option) => !this.previousSelection.includes(option)
    );

    // If there is a new selection, update the latestSelectedAccount
    if (newSelections.length > 0) {
      // Get the last selected account ID
      const latestAccountId = newSelections[newSelections.length - 1];

      // If using split, just in case unwanted characters are present
      const splitAccountId = latestAccountId
        .split(':')
        .pop()
        ?.trim()
        .replace(/'/g, ''); // Split and trim

      // Store only the account ID
      this.latestSelectedAccount = splitAccountId || null;

      // Log the latest selected account directly

      // Call the action method with the latest selected account
      // this.serviceNames(this.latestSelectedAccount);
    }
    this.serviceNames(this.childs);
    // Update the previous selection list
    this.previousSelection = selectedOptions;
  }

  async openPopup(serviceName: any) {
    this.notifier.loading(true);
    await this.setHeadersBasedOnService(serviceName);
    console.log('headers_pop', this.headers_pop);
    const serviceMapping: { [key: string]: string } = {
      'Amazon Simple Storage Service': 's3',
      'Amazon Relational Database Service': 'rds',
      'Amazon Elastic Compute Cloud': 'ec2',
      'Amazon CloudFront': 'cfrc',
    };

    // Set the selected service's abbreviated name
    this.selectedService = serviceMapping[serviceName] || serviceName;
    console.log('selected service in popup', this.selectedService);
    this.selectedClientName = serviceName;
    this.notifier.loading(true);
    // this.popupData = [];
    this.service_Name = serviceName;
    if (this.lek == undefined) {
      this.notifier.loading(true);

      this.fetchDetails(serviceName);
      this.notifier.loading(false);
    }
    // this.fetchDetails(serviceName);
    // Set a flag to show the popup

    console.log(
      'serviceData[selectedService]',
      this.serviceData[this.selectedService]
    );
    this.showPopup = true;

    this.notifier.loading(false);
  }

  closePopup() {
    this.notifier.loading(true);
    this.service_Name = undefined;
    this.showPopup = false;
    this.popupData = [];
    this.notifier.loading(false);
    this.lek = undefined;
  }

  // async fetchDetails(serviceName: any){

  //   this.notifier.loading(true);
  //   const serviceMapping: { [key: string]: string } = {
  //     'Amazon Simple Storage Service': 's3',
  //     'Amazon Relational Database Service': 'rds',
  //     'Amazon CloudFront': 'cfrc',
  //     'Amazon Elastic Compute Cloud': 'ec2'
  //   };

  //   // Get the abbreviated service name from the mapping
  //   const abbreviatedServiceName = serviceMapping[serviceName] || serviceName;
  //   let data;
  //   if (this.lek === null || this.lek === undefined || this.lek === '') {
  //     (this as any)[abbreviatedServiceName] = [];
  //     data = {
  //       month: this.date,
  //       childs: [
  //         this.accountId
  //       ],
  //       mid: this.mid,
  //       a: abbreviatedServiceName,
  //      };
  //   }
  //   else{
  //     data = {
  //       month: this.date,
  //       childs: [
  //         this.accountId
  //       ],
  //       mid: this.mid,
  //       a: abbreviatedServiceName,
  //       lek: this.lek,
  //      };
  //   }

  //   let header = {
  //     Authorization: localStorage.getItem('t'),
  //   };
  //   let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/mrr`;

  //   let result = await this.apiService.postDataPromis(apiURL, data, header);

  //   if (result.s == '1') {
  //     console.log("result lek", result.lek);
  //     if (!result.lek) {
  //       (this as any)[abbreviatedServiceName] = [...((this as any)[abbreviatedServiceName] || []), ...result.riDetails];
  //       this.lek = result.lek;
  //     } else {
  //       this.lek = result.lek;
  //       (this as any)[abbreviatedServiceName] = [...((this as any)[abbreviatedServiceName] || []), ...result.riDetails];
  //     }
  //     console.log(`${abbreviatedServiceName}:`, (this as any)[abbreviatedServiceName]);
  //   }
  //   else {
  //     (this as any)[abbreviatedServiceName] = [];
  //     this.lek = null; // or another value as needed
  //   }
  //   this.popupData = (this as any)[abbreviatedServiceName];
  //   this.notifier.loading(false);

  // }

  async fetchDetails(serviceName: any) {
    this.notifier.loading(true);

    const serviceMapping: { [key: string]: string } = {
      'Amazon Simple Storage Service': 's3',
      'Amazon Relational Database Service': 'rds',
      'Amazon CloudFront': 'cfrc',
      'Amazon Elastic Compute Cloud': 'ec2',
    };

    const abbreviatedServiceName = serviceMapping[serviceName] || serviceName;

    let data;
    if (this.lek === null || this.lek === undefined || this.lek === '') {
      this.serviceData[abbreviatedServiceName] = [];
      data = {
        month: this.date,
        childs: [this.accountId],
        mid: this.mid,
        a: abbreviatedServiceName,
      };
    } else {
      data = {
        month: this.date,
        childs: [this.accountId],
        mid: this.mid,
        a: abbreviatedServiceName,
        lek: this.lek,
      };
    }

    let header = { Authorization: localStorage.getItem('t') };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/mrr`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1') {
      if (!result.lek) {
        this.serviceData[abbreviatedServiceName] = [
          ...(this.serviceData[abbreviatedServiceName] || []),
          ...result.riDetails,
        ];
        this.lek = result.lek;
      } else {
        this.lek = result.lek;
        this.serviceData[abbreviatedServiceName] = [
          ...(this.serviceData[abbreviatedServiceName] || []),
          ...result.riDetails,
        ];
      }
    } else {
      this.serviceData[abbreviatedServiceName] = [];
      this.lek = null;
    }

    this.popupData = this.serviceData[abbreviatedServiceName];
    this.notifier.loading(false);
  }

  // async serviceNames(id:string) {
  //   this.notifier.loading(true);

  //   let data: any = {
  //     masterAccId: this.mid,
  //     lid: id,
  //     action: 'supportPlanDetails',
  //     month_year: this.date,
  //     userType: 'C',
  //   };
  //   let header = {
  //     Authorization: localStorage.getItem('t'),
  //   };
  //   let apiURL = `https://api.swayam.cloud/v3/admin/billing/getservicelevelbill`;
  //   let result = await this.apiService.postDataPromis(apiURL, data, header);
  //   let list: any;
  //   // this.serviceNamelist = [];
  //   if (result.status == '1' || result.s == '1') {
  //     list = result['billDetails']['items'][0]['services'];
  //     let i = 1;

  //     for (let ls in list) {
  //       this.serviceNamelist.push(list[ls]['service_name']);
  //     }

  //     this.serviceNamelist = Array.from(new Set(this.serviceNamelist));

  //     console.log("servicename",this.serviceNamelist);
  //     console.log("servicename length",this.serviceNamelist.length);

  //     setTimeout(async () => {
  //       $('#serviceNameId').selectpicker();
  //       if (this.action.trim() == 'update' || this.mrr.serviceName.length > 0) {
  //         $('#serviceNameId').val([this.mrr.serviceName]);
  //       } else if (this.action.trim() == 'add' && this.unsaved.length > 0) {
  //         const selectElement = document.getElementById(
  //           'serviceNameId'
  //         ) as HTMLSelectElement;

  //         const optionsToDisable = this.unsaved.map(
  //           (item: { serviceName: string }) => item.serviceName
  //         );

  //         for (let i = 0; i < selectElement.options.length; i++) {
  //           if (optionsToDisable.includes(selectElement.options[i].value)) {
  //             selectElement.options[i].disabled = true;
  //             selectElement.options[i].style.color = 'grey';
  //             selectElement.options[i].style.backgroundColor = '#f5f5f5';
  //           }
  //         }
  //       }
  //       $('#serviceNameId').selectpicker('refresh');
  //       this.notifier.loading(false);

  //     }, 100);
  //   } else {
  //         this.notifier.loading(false);

  //   }

  // }

  async serviceNames(id: string) {
    this.notifier.loading(true);
    let data: any = {
      month: this.date,
      childs: id,
      mid: this.mid,
      entity: 'AWS',
      a: 'serviceList',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/mrr`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    let list: any;
    // this.serviceNamelist = [];
    if (result.status == '1' || result.s == '1') {
      this.serviceNamelist = result.services;
      setTimeout(async () => {
        $('#serviceNameId').selectpicker();
        if (this.action.trim() == 'update' || this.mrr.serviceName.length > 0) {
          $('#serviceNameId').val([this.mrr.serviceName]);
        } else if (this.action.trim() == 'add' && this.unsaved.length > 0) {
          const selectElement = document.getElementById(
            'serviceNameId'
          ) as HTMLSelectElement;

          const optionsToDisable = this.unsaved.map(
            (item: { serviceName: string }) => item.serviceName
          );

          for (let i = 0; i < selectElement.options.length; i++) {
            if (optionsToDisable.includes(selectElement.options[i].value)) {
              selectElement.options[i].disabled = true;
              selectElement.options[i].style.color = 'grey';
              selectElement.options[i].style.backgroundColor = '#f5f5f5';
            }
          }
        }
        $('#serviceNameId').selectpicker('refresh');
      }, 100);
    } else {
      this.serviceNamelist = [];
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  selectAllOpt() {
    this.notifier.loading(true);
    this.isAllSelect = !this.isAllSelect;

    if (this.serviceNamelist.includes('Amazon Elastic Compute Cloud') && this.isAllSelect) {
      console.log("testing", this.serviceNamelist.includes('Amazon Elastic Compute Cloud'));
      this.showEC2DiscountFields = true;
    } else {
      this.showEC2DiscountFields = false;
      console.log("testing", this.serviceNamelist.includes('Amazon Elastic Compute Cloud'));

    }
    
    setTimeout(async () => {
      $('#serviceNameId').selectpicker();
      if (this.isAllSelect) {
        $('#serviceNameId').val(this.serviceNamelist);
      } else {
        $('#serviceNameId').val([]);
      }
      $('#serviceNameId').selectpicker('refresh');
      this.notifier.loading(false);
    }, 100);
  }
  showDiscountFields: boolean = false;
  onServiceNameChange(event: any) {
    const selectedOptions = Array.from(event.target.options)
      .filter((option: any) => option.selected)
      .map((option: any) => option.value);
    // Check if 'Cloud Computing' is selected
    this.showS3DiscountFields = false;
    this.showRDSDiscountFields = false;
    this.showCloudFrontDiscountFields = false;
    this.showEC2DiscountFields = false;

    // Loop through selected options and set variables accordingly
    selectedOptions.forEach((option) => {
      switch (option) {
        case 'Amazon Simple Storage Service':
          this.showS3DiscountFields = true;
          this.fetchDetails('Amazon Simple Storage Service');
          break;
        case 'Amazon Relational Database Service':
          this.showRDSDiscountFields = true;
          this.fetchDetails('Amazon Relational Database Service');
          break;
        case 'Amazon CloudFront':
          this.showCloudFrontDiscountFields = true;
          this.fetchDetails('Amazon CloudFront');
          break;
        case 'Amazon Elastic Compute Cloud':
          this.showEC2DiscountFields = true;
          this.fetchDetails('Amazon Elastic Compute Cloud');
          break;
        default:
          break;
      }
    });
  }

  notSelectedService() {
    let selected: any = [];
    let newSelected = this.selectedServiceNames.map((item) => item.serviceName);
    if (this.unsaved.length == 0) {
      selected = this.selectedServiceNames.map(
        (service) => service.serviceName
      );
    } else {
      selected = this.unsaved.map((service: any) => service.serviceName);
      selected = selected.concat(newSelected);
    }

    this.unselectedService = this.serviceNamelist.filter(
      (item: any) => !selected.includes(item)
    );
  }
  ngOnDestroy(): void {}

  trackByFn() {
    return true;
  }

  close(dataAction: any) {
    if (dataAction) {
      this.hideModel.emit(dataAction);
    } else {
      this.hideModel.emit(dataAction);
    }
  }
}
