import {
  Component,
  OnDestroy,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import { param } from 'jquery';

declare let $: any;
declare let window: any;
declare let Jhxlsx: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './erp-pl-report.component.html',
  styleUrls: ['./erp-pl-report.component.css'],
})
export class ErpPlReportComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') attachmentElement: ElementRef;
  // @Input() selectedType:string;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  cfrcData: any = [];
  dashData: any = [];
  funRef: any;
  accountId: any;
  regionId: any;
  currentMessage: any;
  editable: any = [];
  upload: boolean = false;
  showConditionalElement = false;
  list: any = [];
  s3Url: any;
  isModalVisible: boolean;
  allCallsCompleted: boolean;

  editableHeader: any = [
    {
      id: 'Child Acc',
      name: 'Linked Account ID',
      editable: true,
    },
    {
      id: 'Master Acc',
      name: 'Master Account ID',
      editable: true,
    },
    {
      id: 'Amount',
      name: 'Amount',
      editable: true,
    },
    {
      id: 'AmountUnbill',
      name: 'AmountUnbill',
      editable: true,
    },
  ];

  editMode: boolean = false;

  headers: any = [
    {
      id: 'mId',
      name: 'Mstr acct Id',
      click: 'loadChildData',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
    },
    {
      id: 'accountName',
      name: 'Mstr Name',
      show: true,
      type: 'String',
      filter: true,
      style: { width: 0 },
    },
    {
      id: 'awsTotalCost',
      name: 'AWS Usage',
      sort: { sort: true, direction: 'Asc' },
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'marketPlaceFee',
      name: 'Mktp Fee',
      sort: { sort: true, direction: 'Asc' },
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'marketPlace',
      name: 'Mktp Usage',
      sort: { sort: true, direction: 'Asc' },
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'credits',
      name: 'Credits',
      sort: { sort: true, direction: 'Asc' },
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'refund',
      name: 'Margin',
      sort: { sort: true, direction: 'Asc' },
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'marginPerc',
      name: 'Margin Percentage',
      sort: { sort: true, direction: 'Asc' },
      alter: (item: any, param: string) => {
        return `${item[param]}%`;
      },
    },
    {
      id: 'netAmount',
      name: 'Purchase',
      sort: { sort: true, direction: 'Asc' },
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'minfyTotalCost',
      name: 'Swayam Cost',
      sort: { sort: true, direction: 'Asc' },
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },

    {
      id: 'erp',
      name: 'Sale',
      sort: { sort: true, direction: 'Asc' },
      alter: (item: any, param: string) => {
        return `$${item[param]}` + (item['edited'] ? '*' : '');
      },
    },
    {
      id: 'pl',
      name: 'S-PnL',
      sort: { sort: true, direction: 'Asc' },
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
      color: (value: any) => {
        if (!value || value.toLowerCase() == 'nan') {
          return 'inherit';
        }
        return `${Number(value) > 0 ? 'green' : 'red'}`;
      },
    },
    {
      id: 'pl_percentage',
      name: 'S-PnL (%)',
      sort: { sort: true, direction: 'Asc' },
      color: (value: any) => {
        if (!value || value.toLowerCase() == 'nan') {
          return 'inherit';
        }
        return `${Number(value) > 0 ? 'green' : 'red'}`;
      },
      alter: (item: any, param: string) => {
        return `${item[param]}%`;
      },
    },
    {
      id: 'unBilledCost',
      name: 'Unbilled Cost',
      sort: { sort: true, direction: 'Asc' },
      alter: (item: any, param: string) => {
        return `$${item[param]}` + (item['edited'] ? '*' : '');
      },
    },
    {
      id: 'plForUnbill',
      name: 'PnL',
      sort: { sort: true, direction: 'Asc' },
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
      color: (value: any) => {
        if (!value || value.toLowerCase() == 'nan') {
          return 'inherit';
        }
        return `${Number(value) > 0 ? 'green' : 'red'}`;
      },
    },
    {
      id: 'plPercentageForUnbill',
      name: 'PnL (%)',
      sort: { sort: true, direction: 'Asc' },
      color: (value: any) => {
        if (!value || value.toLowerCase() == 'nan') {
          return 'inherit';
        }
        return `${Number(value) > 0 ? 'green' : 'red'}`;
      },
      alter: (item: any, param: string) => {
        return `${item[param]}%`;
      },
    },
  ];

  child_headers: any = [
    {
      id: 'lId',
      name: 'Child Acct Id',
    },
    {
      id: 'joinedMethod',
      name: 'Joined Method',
    },
    {
      id: 'joinedDate',
      name: 'Joined Date',
    },
    {
      id: 'awsTotalCost',
      name: 'AWS Usage',
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'marketPlaceFee',
      name: 'Mktp Fee',
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'marketPlace',
      name: 'Mktp',
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'credits',
      name: 'Credits',
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'refund',
      name: 'Margin',
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'marginPerc',
      name: 'Margin Percentage',
      alter: (item: any, param: string) => {
        return `${item[param]}%`;
      },
    },
    {
      id: 'netAmount',
      name: 'Purchase',
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'minfyTotalCost',
      name: 'Swayam Cost',
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'erp',
      name: 'Sale',
      icon: true,
      editable: () => {
        return this.editMode;
      },
      alter: (item: any, param: string) => {
        return (
          `$${item[param]}` + (item['edited'] && item['erpEdit'] ? '*' : '')
        );
      },
    },
    {
      id: 'pl',
      name: 'S-PnL',
      color: (value: any) => {
        if (!value || value.toLowerCase() == 'nan') {
          return 'inherit';
        }
        return `${Number(value) > 0 ? 'green' : 'red'}`;
      },
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'pl_percentage',
      name: 'S-PnL (%)',
      color: (value: any) => {
        if (!value || value.toLowerCase() == 'nan') {
          return 'inherit';
        }
        return `${Number(value) > 0 ? 'green' : 'red'}`;
      },
      alter: (item: any, param: string) => {
        return `${item[param]}%`;
      },
    },
    {
      id: 'unBilledCost',
      name: 'Unbilled Cost',
      editable: () => {
        return this.editMode;
      },
      alter: (item: any, param: string) => {
        return `$${item[param]}` + (item['edited'] ? '*' : '');
      },
    },
    {
      id: 'plForUnbill',
      name: 'PnL',
      color: (value: any) => {
        if (!value || value.toLowerCase() == 'nan') {
          return 'inherit';
        }
        return `${Number(value) > 0 ? 'green' : 'red'}`;
      },
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      id: 'plPercentageForUnbill',
      name: 'PnL(%)',
      color: (value: any) => {
        if (!value || value.toLowerCase() == 'nan') {
          return 'inherit';
        }
        return `${Number(value) > 0 ? 'green' : 'red'}`;
      },
      alter: (item: any, param: string) => {
        return `${item[param]}%`;
      },
    },
  ];
  cfrc_start_date: any;
  cfrc_end_date: any;
  dateRange: any;
  masterAccounts: any = [];
  selectedOption: string;
  i: any;
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.selectedOption = params['selectedOption'];
      this.dateRange = params['month-year'];
    });

    this.funRef = {
      loadChildData: this.loadChildData,
    };
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.load(),
      component: this,
    };

    let temp = moment();
    $('#monthDate').val(
      this.dateRange || `${temp.format('MM')}-${temp.format('YYYY')}`
    );
    $('#monthDate')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm-yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  masterStatuses: any;
  async delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  
  async erpAll(ref: any) {
      // Create a new array to store the status for each masterId
    this.masterStatuses = this.cfrcData.map((acc: any) => ({
      name: acc.accountName, // Keep the name for display
      masterId: acc.mId, // Assuming `masterId` exists in `cfrcData`
      isLoading: false,
      isSuccess: false,
      isError: false,
    }));
    this.isModalVisible = true;
    
    // Process each API call sequentially with delay
    for (const status of this.masterStatuses) {
      status.isLoading = true; // Set loading state
      try {  
        // Load child data (if needed before loadErp)
       await this.loadChildData(this, null, status.masterId);
  
        // Introduce a delay before the next API call
        await this.delay(100);
  
        // Call loadErp method and wait for its completion
        await this.loadErp(ref, status.masterId);
  
        status.isSuccess = true; // Mark as successful if no errors
      } catch (error) {
        status.isError = true; // Mark as error in case of failure
      } finally {
        status.isLoading = false; // Clear loading state
      }
    }
    this.selectedMID = null
    // Set all calls completed
    this.allCallsCompleted = true;
  }
  
  
  

  closeModal(){
    this.isModalVisible = false;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  closeUpload() {
    this.upload = false;
    this.editable = [];
  }

  back() {
    this.selectedMID = null;
    this.editMode = false;
  }

  edit() {
    if (this.editMode) {
      this.loadChildData(this, null, this.selectedMID);
    }
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.childAccountData = this.childAccountData.map((lid: any) => {
        lid['Amount'] = lid['erp'];
        lid['AmountUnbill'] = lid['unBilledCost'];
        return lid;
      });
    }
  }

  async saveDetails() {
    let dt: any;
    if (this.editMode) {
      dt = this.childAccountData
        .filter((cost: any) => {
          return (
            cost['Amount'] != cost['erp'] ||
            cost['AmountUnbill'] != cost['unBilledCost']
          );
        })
        .map((cost: any) => {
          return {
            mid: this.selectedMID,
            lid: cost['lId'],
            amount: cost['erp'],
            amountUnbill: cost['unBilledCost'],
          };
        });
      if (dt.length == 0) {
        this.edit();
        return;
      }
    } else {
      dt = this.editable.map((cost: any) => {
        let dt: any = {};
        dt['mid'] = cost['Master Acc'];
        dt['lid'] = cost['Child Acc'];
        dt['amount'] = cost['Amount'];
        dt['amountUnbill'] = cost['AmountUnbill'];
        return dt;
      });
    }

    this.notifier.loading(true);
    let data: any = { a: 'updateErp', m: $('#monthDate').val(), erpData: dt };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchchildaccwithcosts/fetchchildaccwithcostsv2`;
    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      if (this.editMode && this.selectedMID) {
        await this.loadChildData(this, null, this.selectedMID);
        this.editMode = false;
      } else {
        this.closeUpload();
      }
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }
  selectedDate: any = null;
  async load() {
    this.notifier.loading(true);
    await this.loadMasterAccounts();
    this.notifier.loading(false);
    this.selectedDate = $('#monthDate').val();
  }

  childAccountData: any = null;
  selectedMID: any = null;

  async loadChildData(ref: any, lek: any = undefined, lid: any) {
    // console.log('>>data child' + JSON.stringify(lid));
    if (typeof lid == 'object') {
      ref.selectedMID = lid['mId'];
    } else {
      ref.selectedMID = lid;
    }
    ref.notifier.loading(true);
    if (!lek) {
      ref.childAccountData = [];
    }
    let data = {
      a: 'fetch',
      m: $('#monthDate').val(),
      lek: lek,
      mId: ref.selectedMID,
      isDevUser: '0',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/billing/fetchchildaccwithcosts/fetchchildaccwithcostsv2`;
    let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    if(this.isModalVisible){
      this.selectedMID = null;
    }

    if (result.status == '1' || result.s == '1') {
      ref.childAccountData = [
        ...ref.childAccountData,
        ...result.billingDetailsList,
      ];
    } else {
      ref.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    if (result.lek && Object.keys(result.lek).length > 0) {
      ref.loadMasterAccounts(result.lek);
    }
    ref.notifier.loading(false);
  }

  fetchAccountID: string;
  async loadMasterAccounts(lek: any = undefined) {
    // if (
    //   this.masterAccounts.length > 0 &&
    //   this.fetchAccountID == this.accountId
    // ) {
    //   await this.loadCFRCData($('#masterAccountSelect').val());
    //   return;
    // }
    this.notifier.loading(true);

    if (!lek) {
      this.cfrcData = [];
    }
    let data = {
      a: 'fetchAll',
      m: $('#monthDate').val(),
      lek: lek,
      isDevUser: '0',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchchildaccwithcosts/fetchchildaccwithcostsv2`;
    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.cfrcData = [...this.cfrcData, ...result.billingDetailsList];
      this.list = [...this.list, ...result.billingDetailsList];
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    if (result.lek && Object.keys(result.lek).length > 0) {
      this.loadMasterAccounts(result.lek);
    }
    this.notifier.loading(false);
  }

  async loadCFRCData(masterAccId: string) {
    let sDate = $('#monthDate').val();
    sDate = moment(sDate, 'MM/YYYY').format('MM-YYYY');
    let data = {
      month_year: sDate,
      lid: this.accountId,
      userType: 'C',
      action: 'fetchCloudFrontData',
      masterAccId: masterAccId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      if (result.cloudFrontDetailsList) {
        this.cfrcData = result.cloudFrontDetailsList;
      } else {
        this.cfrcData = [];
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
  }

  triggerFIle() {
    this.attachmentElement.nativeElement.value = '';
    this.attachmentElement.nativeElement.click();
  }

  checkFile(event: any) {
    let file = event.target.files[0];
    if (
      file.name.indexOf('.xlsx') > -1 ||
      file.name.indexOf('.xls') > -1 ||
      file.name.indexOf('.csv') > -1
    ) {
      var r = new FileReader();
      r.onload = (e) => {
        var contents = window.processExcel(e.target.result);
        let data = JSON.parse(contents);
        data = data[Object.keys(data)[0]];
        let headers: any = data[0];
        let valid = true;
        headers.forEach((element: any) => {
          if (['Child Acc', 'Master Acc', 'Amount'].indexOf(element) <= -1) {
            valid = false;
          }
        });
        if (!valid) {
          this.notifier.alert('Info', '', 'Not a Valid File', 'info', 5000);
          return;
        }
        let dt: any = [];
        for (let i = 1; i < data.length; i++) {
          let obj: any = {};
          for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = data[i][j];
          }
          dt.push(obj);
        }
        this.editable = dt;
        this.upload = true;
      };
      r.readAsBinaryString(file);
    } else {
      this.notifier.alert('Info', '', 'File is not supported', 'info', 5000);
    }
  }

  export() {
    this.showConditionalElement = true;
    let data: any = [
      {
        sheetName: 'PnL Report',
        data: [
          (this.selectedMID ? this.child_headers : this.headers).map(
            (h: any) => {
              return { text: h.name };
            }
          ),
        ],
      },
    ];

    let sheetData: any = this.selectedMID
      ? this.childAccountData
      : this.cfrcData;
    let headers: any = this.selectedMID ? this.child_headers : this.headers;

    sheetData.map((item: any) => {
      let data_: any = [];
      headers.forEach((h: any) => {
        data_.push({ text: h.alter ? h.alter(item, h.id) : item[h.id] });
      });

      data[0]['data'].push(data_);
    });

    var options = {
      fileName: this.selectedMID
        ? `PnL Report - ${this.selectedMID}`
        : 'PnL Report',
    };

    Jhxlsx.export(data, options);
  }
  async completeExcelApi() {
    this.notifier.loading(true);
    let data = {
      a: 'export',
      m: $('#monthDate').val(),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchchildaccwithcosts/fetchchildaccwithcostsv2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.s3Url = result.s3Url;
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.notifier.loading(false);
    }
    if (this.s3Url) {
      window.open(this.s3Url, '_blank');
      this.notifier.loading(false);
    } else {
      console.error('error in fetching excel');
      this.notifier.loading(false);
    }
  }

  async loadErp(ref: any, mId: any, lId: any = '') {
    if(!this.isModalVisible){
      ref.notifier.loading(true);
    }    
    let accountsDetails: any[] = [];
    if (lId == '') {
      this.childAccountData.map((item: any) =>
        accountsDetails.push({ lid: item['lId'], mid: mId })
      );
    } else {
      accountsDetails.push({ lid: lId, mid: mId });
    }
    let data = {
      a: 'fetcherp',
      monthYear: $('#monthDate').val(),
      accounts: accountsDetails,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/billing/erp`;
    let result = await ref.apiServer.postDataPromis(apiURL, data, header);
    console.log('result  ' + JSON.stringify(result));
    if (result.status == '1' || result.s == '1') {
      this.loadChildData(this, null, this.selectedMID);
      // ref.notifier.alert('Info', '', result.msg, 5000);
    } else if (result.message == 'Endpoint request timed out') {
      ref.notifier.alert(
        'Info',
        '',
        'Please refresh the page after 5 min',
        5000
      );
    } else {
      if(!this.isModalVisible){
      ref.notifier.alert('Info', '', result.msg, 5000);
      }
      this.notifier.alert('Info', '', result.msg, 'info', 5000);

    }
    if(!this.isModalVisible){
    ref.notifier.loading(false);
    }
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, undefined, param);
  }
}
