import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';
import { regions } from './../../../../dash/dash.component';

declare let $: any;
declare let flatpickr: any;

@Component({
  selector: 'ec2-cross-update',
  templateUrl: './update-ec2.component.html',
  styleUrls: ['./update-ec2.component.css'],
})
export class Ec2crossUpdateComponent implements OnInit, OnDestroy {
  additionalContacts: string[] = [''];
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  regions: any = regions;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  regionId: any;
  dateRange: any;
  RITypes: any = [];
  selectedOption: any;
  currentMessage: any;
  @Output() hideModel = new EventEmitter<any>();
  @Input() ri: any;
  @Input('action') action: any;
  instanceTypes: any;
  platformTypes: any;
  masterAccounts: any;
  mid: any;
  selectedClientName: string;
  selectedChild: { id: number; name: string }[] = [];
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.ri = { ...this.ri };

    if (this.action !== 'add') {
      this.ri.ramp = this.ri.ramp === 'Yes';
    }

    $('#mislist').selectpicker();

    $('#childlist').selectpicker();

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });

    flatpickr('#dataRangePicker', {
      dateFormat: 'Y-m-d',
      mode: 'range',
      showMonths: 2,
      defaultDate: this.dateRange ? this.dateRange.split(' to ') : null,
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        if (!dateStr.includes('to')) return;
        this.dateRange = dateStr;
      },
    });
    if (this.action !== 'add') {
      this.dateRange = this.ri.startDate + ' to ' + this.ri.endDate;
    }
  }

  load() {
    this.fetchMasterAccounts();
  }

  toggleButton() {
    this.ri.rmp = !this.ri.rmp; // Toggle between true and false
  }

  dropdownOptions: any;

  async fetchMasterAccounts() {
    this.masterAccounts = [];
    this.notifier.loading(true);
    let data = {
      a: 'mids',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/lid-mid`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.masterAccounts = result.mids;
      this.mid = this.masterAccounts[0];
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.selectedClientName = localStorage.getItem('selectedClientName');
    if (this.action !== 'add') {
      this.selectedOption = this.masterAccounts.find(
        (master: any) =>
          master.name === this.ri.companyName && master.id === this.ri.mid
      );
      this.childFetch(this.selectedOption.clientId);
    }
    setTimeout(() => {
      $('#mislist').selectpicker('refresh');
    }, 0);
    this.notifier.loading(false);
  }

  childs: any;
  async childFetch(id: any) {
    this.notifier.loading(true);

    let data = {
      a: "getChildAccounts",
      mid: this.selectedOption.id,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/ec2-cross`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.childs = result.lids;
      if (this.action !== 'add') {
        const lidsArray = this.ri.lids.map((lid: any) => lid.id); // Extract IDs from ri.lids
        this.selectedChild = this.childs.filter((child: string) => lidsArray.includes(child));

      }      
      console.log(this.childs)
      console.log(this.selectedChild);      
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    setTimeout(() => {
      // Refresh the select picker to reflect the updated selectedChild
      ($('#childlist') as any).selectpicker('refresh');
    }, 0);
    
    // if (Array.isArray(this.childs) && Array.isArray(this.ri?.lids)) {
    //   if (this.action !== 'add') {
    //     const selectedChildren = this.ri.lids.map((lid:any) => {
    //       return this.childs.find((child:any) => child.id === lid.id && child.name === lid.name); // Match both id and name
    //     }).filter((child:any) => child !== undefined); // Filter out undefined values in case no match is found
    //         this.selectedChild = selectedChildren;
    //   }
    // }
    
    this.notifier.loading(false);
  }

  selectAllchilds(event: any) {
    const isChecked = event.target.checked;
  
    if (isChecked) {
      // Select all accounts
      this.selectedChild = this.childs;
    } else {
      // Deselect all accounts
      this.selectedChild = [];
    }
  
  
    // Refresh the selectpicker dropdown
    setTimeout(() => {
      $('#childlist').selectpicker('refresh');
    }, 0);  // SetTimeout of 0 instead of 10ms
  }
  

  async updateEntry() {
    if (this.action.trim() == 'add') {
      this.notifier.loading(true);
      let data: any = {
        a: 'add',
        companyName: this.selectedOption.name,
        mid: this.selectedOption.id,
        lids: this.selectedChild,
        crossAccount: this.ri.crossAccount
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };
      // let apiURL =
      let apiURL = `https://api.swayam.cloud/v3/admin/billing/ec2-cross`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        this.notifier.alert('Success', '', result.msg, 'success', 5000);
        this.close(true);
      } else {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      }

      this.notifier.loading(false);
    } else {
      this.notifier.loading(true);
      let data: any = {
        a: 'update',
        mid: this.ri.mid,
        lids: this.selectedChild,
        crossAccount: this.ri.crossAccount,
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };
      // let apiURL =
      //   'https://api.swayam.cloud/prod/msopsusers/billing/fetchripurchaseorder';
      let apiURL = `https://api.swayam.cloud/v3/admin/billing/ec2-cross`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        this.notifier.alert('Success', '', result.msg, 'success', 5000);
        this.close(true);
      } else {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      }

      this.notifier.loading(false);
    }
  }

  display() {
  }

  ngOnDestroy(): void {}

  trackByFn() {
    return true;
  }

  close(dataAction: any) {
    if (dataAction) {
      this.hideModel.emit(dataAction);
    } else {
      this.hideModel.emit(dataAction);
    }
  }
}
