import {
    Component,
    OnDestroy,
    OnInit,
    NgZone,
    ViewChild,
    ElementRef,
    Input,
  } from '@angular/core';
  import * as moment from 'moment';
  import { ActivatedRoute } from '@angular/router';
  import { Router } from '@angular/router';
  import { NotifierService } from 'src/app/_services/notifier.service';
  import { APIService } from 'src/app/api/api.service';
  import { param } from 'jquery';
  
  declare let $: any;
  declare let window: any;
  declare let Jhxlsx: any;
  declare let flatpickr: any;
  
  @Component({
    //moduleId: module.id.toString(),
    templateUrl: './erp.component.html',
    styleUrls: ['./erp.component.css'],
  })
  export class ERPComponent implements OnInit, OnDestroy {
    userId = localStorage.getItem('eId');
    ut = localStorage.getItem('ut');
    urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
    writeAccess: boolean =
      localStorage.getItem('acT') == 'readandwrite' ||
      this.urlPrefix == 'admin' ||
      localStorage.getItem('ut') == 'admin';
    loading = true;
    riData: any[] = [];
    updateRI: any;
    updateAction: any = 'update';
    type:any;
    dateRange: any;
  
    accountId: any;
    regionId: any;
    currentMessage: any;
    unsavedRI: any = [];
  
    headers: any = [

      {
        id: 'mid',
        name: 'Account Id',
        sort: { sort: true, direction: 'Asc' },
        filter: true,
      },
      {
        id: 'customerName',
        name: 'Customer Name',
        sort: { sort: true, direction: 'Asc' },
        filter: true,
      },
      {
        id: 'customerId',
        name: 'Customer Id',
        sort: { sort: true, direction: 'Asc' },
        filter: true,
      },

      {
        id: 'erpOrderNumber',
        name: 'ERP Order Number',
        sort: { sort: true, direction: 'Asc' },
        filter: true,
      },
      {
        id: 'currencyCode',
        name: 'Currency Code',
        sort: { sort: true, direction: 'Asc' },
        filter: true,
      },
      {
        id: 'awsCost',
        name: 'AWS Cost',
        sort: { sort: true, direction: 'Asc' },
      },
      {
        id: 'mpCost',
        name: 'Marketplace Cost',
        sort: { sort: true, direction: 'Asc' },
      },

      {
        id: 'spCost',
        name: 'Support Plan Cost',
        sort: { sort: true, direction: 'Asc' },
      },
      {
        id: 'totalCost',
        name: 'Swayam Total Cost',
        sort: { sort: true, direction: 'Asc' },
      },
    ];

    child_headers:any = [
      {
        id: 'lid',
        name: 'Linked Account ID'
      },
      {
        id: 'awsCost',
        name: 'AWS Cost'
      },
      {
        id: 'mpCost',
        name: 'Market Place Cost'
      },

      {
        id: 'spCost',
        name: 'Support Plan Cost'
      },
      {
        id: 'totalCost',
        name: 'Swayam Total Cost'
      },
    ];
  
    funRef: any = {};
    deleteRI: any;
    unsavedUpdateId: number = -1;
    child: any;
  
    constructor(
      private notifier: NotifierService,
      private apiService: APIService,
      private zone: NgZone,
    ) {}
  
    ngOnInit(): void {
      this.accountId = localStorage.getItem('accountId');
      this.regionId = localStorage.getItem('regionId');
      this.funRef = {};
  
      this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
        let d = JSON.parse(msg);
        if (d.value == null) {
          return;
        }
        if (d.key == 'accountId') {
          this.accountId = d.value;
          this.load();
        } else if (d.key == 'regionId') {
          this.regionId = d.value;
          this.load();
        }
      });

      window.angularComponentReference = {
        zone: this.zone,
        componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
          this.load(),
        component: this,
      };

      let temp = moment();
      $('#monthDate').val(
        this.dateRange || `${temp.format('MM')}-${temp.format('YYYY')}`
      );
      $('#monthDate')
        .datepicker({
          autoclose: true,
          minViewMode: 1,
          format: 'mm-yyyy',
          orientation: 'bottom auto',
          endDate: new Date(),
        })
        .on('changeDate', function (selected: any) {
          window.angularComponentReference.zone.run(() => {
            window.angularComponentReference.componentFn();
          });
        });

      flatpickr('#dataRangePicker', {
        dateFormat: 'Y-m-d',
        mode: 'range',
        showMonths: 2,
        defaultDate: this.dateRange ? this.dateRange.split(' to ') : null,
        onChange: (selectedDates: any, dateStr: any, instance: any) => {
          if (!dateStr.includes('to')) return;
          this.dateRange = dateStr;
        },
      });
    }
    period:any;
    async load() {
      this.notifier.loading(true);
  
      let data = { 
        action: "fetchAll",
        monthYear: $('#monthDate').val(),
       };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `https://api.swayam.cloud/v3/admin/billing/erp-new`;
      let result = await this.apiService.postDataPromis(apiURL, data, header);
      if (result.status == '1' || result.s == '1') {
        this.riData = result.data.items;

      } else {
        this.riData = [];
        // console.log("length of array",this.riData.length);
        this.notifier.alert('Info', '', result.m, 'info', 5000);
      }
      this.notifier.loading(false);
    }
    popup = false;
    async childload(id:any){
      this.notifier.loading(true);
      
      this.popup = true;
      let data = { 
        action: "fetchChildLevel",
        monthYear: $('#monthDate').val(),
        mid: id.mid,
       };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `https://api.swayam.cloud/v3/admin/billing/erp-new`;
      let result = await this.apiService.postDataPromis(apiURL, data, header);
      if (result.status == '1' || result.s == '1') {
        this.child = result.data.items;

      } else {
        this.child = [];
        // console.log("length of array",this.riData.length);
        this.notifier.alert('Info', '', result.m, 'info', 5000);
      }
      this.notifier.loading(false);
    }

    closePopup(){
      this.child =[] ;
      this.popup = false;
    }
  
    updateTrigger(id: any) {
      this.updateAction = 'update';
      this.updateRI = id;
    }
  
    deleteUnsavedRI(id: number) {
      this.unsavedRI.splice(id, 1);
    }
  
    updateUnsavedRI(id: any, index: number) {
      this.updateAction = 'save';
      this.unsavedUpdateId = index;
      this.updateRI = id;
    }
  
    closeUpdateTrigger(event: any) {
      // console.log('closeUpdateTrigger', event);
      if (event) {
        // console.log('in event');
        // if (this.updateAction == 'update') {
          this.load();
        // } else {
          
        //   if (this.unsavedUpdateId > -1) {
        //     this.unsavedRI[this.unsavedUpdateId] = event.data[0];
        //     this.unsavedUpdateId = -1;
        //   } else {
        //     if(event.type == 'all'){
        //       // console.log('in all');
        //       this.load();
        //     }
        //     else{
        //       this.unsavedRI.push(event.data[0]);
  
        //     }
        //   }
        // }
      }
  
      this.updateRI = null;
    }
    indexdelete:any;
    delete: boolean =false;
    deleteall: boolean = false;
  
    confirmdeletion(i:any){
      this.delete = true;
      this.indexdelete = i;
    }
  
  
   async deleteTriggerall(){
    this.deleteall = false;
        this.notifier.loading(true);
        let data: any = {
          accountId: this.accountId,
          action: 'deleteAll',
        };
    
        let header = {
          Authorization: localStorage.getItem('t'),
        };
      let apiURL = `https://api.swayam.cloud/v3/admin/billing/erp-new`;
      let result = await this.apiService.postDataPromis(apiURL, data, header);
        if (result.status == '1' || result.s == '1') {
          this.notifier.alert('Success', '', result.msg, 'success', 5000);
          this.load();
          this.delete = false;
        } else {
          this.notifier.alert('Info', '', result.msg, 'info', 5000);
          this.delete = false;
        }
    
        this.notifier.loading(false);
    }
    async deleteTrigger(id: any) {
      this.notifier.loading(true);
      let data: any = {
        accountId: this.accountId,
        action: 'delete',
        uuid: id.uuid,
      };
  
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `https://api.swayam.cloud/v3/admin/billing/erp-new`;
      let result = await this.apiService.postDataPromis(apiURL, data, header);
      if (result.status == '1' || result.s == '1') {
        this.notifier.alert('Success', '', result.msg, 'success', 5000);
        this.load();
        this.delete = false;
      } else {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
        this.delete = false;
      }
  
      this.notifier.loading(false);
    }
  
    closeDeleteTrigger(event: any) {
      this.deleteRI = null;
    }
  
    addPurchase() {
      this.updateAction = 'save';
      this.unsavedUpdateId = -1;
  
      this.updateRI = {
        accountId: this.accountId,
        description: "",
        regionn: [],
        usageType: [],
        unit: "",
        ppu: "",
        discountType: 'percentage',
        discountPercentage:'',
        linkedAccounts: [],
        endDate: moment().startOf('day').format('YYYY-MM-DD'),
        startDate: moment().startOf('day').subtract(1).format('YYYY-MM-DD'),
      };
    }
  
  
    callFunction(param: any, id: any) {
      this.funRef[param](this, id);
    }
  
    ngOnDestroy(): void {
      this.currentMessage.unsubscribe();
    }
  }
  

  