<div class="alarm_screen" style="box-shadow: 1px 3px 1px #80808024">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform: capitalize">
        {{ action == "update" ? "update" : "add" }} MRR
      </h4>
    </div>
    <div class="alarm_body" style="padding: 1px 25px 5px 25px;max-height: 600px; overflow-y: auto;">
      <div *ngIf="action !== 'update'">
        <div class="row">
          <div class="flex-between p-0">
            <label>Linked Account List</label>
            <div class="flex-start filters">
              <div class="flex-start">
                <input type="checkbox" id="select_all_accounts" (change)="selectAllchilds($event)" />
                <label for="select_all_accounts">Select All</label>
              </div>
            </div>
          </div>

          <select class="form-control" id="linkedAccounts" [(ngModel)]="childs" (change)="onAccountChange($event)"
            data-live-search="true" multiple required>
            <option *ngFor="let master of linkedAccounts['list']" [value]="master.accountId">
              {{ master.accountId }} ({{master.accountName}})
            </option>
          </select>

        </div>
        <div class="row">
          <div class="flex-between p-0">
            <label>Service Name</label>
            <div class="p-0 flex-start">
              <input type="checkbox" name="" (click)="selectAllOpt()" id="service_name" />
              <label for="service_name">Select All</label>
            </div>
          </div>
          <div class="p-0">
            <select id="serviceNameId" class="form-control selectpicker" data-live-search="true"
              [disabled]="action == 'update'" multiple (change)="onServiceNameChange($event)">
              <option value="" disabled>--Select the service name--</option>
              <option *ngFor="let name of serviceNamelist" [value]="name"
                [attr.disabled]="isServiceDisabled(name) ? true : null">
                {{ name }}
              </option>
            </select>
          </div>

        </div>
        <div class="row" *ngIf="action !== 'update'">
          <label>MRR Discount</label>
          <input type="number" class="form-control" [(ngModel)]="mrr.mrrDiscount"
            style="padding: 5px;margin-left: 0px" />
        </div>
      </div>

      <div class="row" >
        <div *ngIf="action == 'update'" style="padding-left: 0px;">
        <div class="p-0">
          <label>Service Name</label>
        </div>
        <div class="p-0">
          <select id="serviceNameId" class="form-control"  [disabled]="action == 'update'">
            <option value="{{ mrr.serviceName }}"> {{ mrr.serviceName }}</option>
          </select>
          <!-- </div> -->
        </div>
      </div>
        <div *ngIf="(mrr.serviceName == 'Amazon Elastic Compute Cloud' || showEC2DiscountFields) && selectedClientName === 'NHA'">
          <div class="font-weight-bold mt-3 d-flex justify-content-between">
            <div>
              EC2 Discount
            </div>
            <!-- <div>
              <span class="view-details" style="cursor: pointer; color: blue;"
                (click)="openPopup('Amazon Elastic Compute Cloud')">View Details</span>
            </div> -->
          </div>
          <div class="border p-3 mb-3">

            <div class="form-group row">
              <label>Others </label>
              <input type="number" class="form-control" [(ngModel)]="mrrDatasub.others" />
            </div>
            <div class="form-group row">
              <label>Instance</label>
              <input type="number" class="form-control" [(ngModel)]="mrrDatasub.instance" />
            </div>
            <div class="form-group row">
              <label>EBS</label>
              <input type="number" class="form-control" [(ngModel)]="mrrDatasub.ebs" />
            </div>
          </div>



        </div>

        <div *ngIf="selectedClientName !== 'NHA'">
          <non_nha [mrrDatasub]="mrrDatasub" [mrr]="mrr" [selectedClientName]="selectedClientName" [action]="action"
            [mid]="mid" [childs]='childs' (mrrDatasubChange)="onMrrDatasubUpdate($event)">></non_nha>
        </div>

        <div *ngIf="action == 'update'" style="padding-left: 0px;">
        <div style="padding-left: 0px;"
          *ngIf="!['Amazon Elastic Compute Cloud', 'Amazon Relational Database Service'].includes(mrr.serviceName)">
          <!-- Content for non-matching service names -->
          <label>MRR Discount</label>
          <div *ngIf="">
            <span class="view-details" style="cursor: pointer; color: blue;"
              (click)="openPopup('Amazon Simple Storage Service')">View Details</span>
          </div>
          <input style="margin-left: 0px;" type="number" class="form-control" [(ngModel)]="mrr.mrrDiscount" />
        </div>
      </div>
      </div>

      <div *ngIf="selectedClientName !== 'NHA'">
        <non_nha [subEc2]="subEc2" [subRds]="subRds" [s3Mrr]="s3Mrr" [cfrcMrr]="cfrcMrr" [mid]="mid"
          [isAllSelect]="isAllSelect" [showEC2DiscountFields]="showEC2DiscountFields"
          [showCloudFrontDiscountFields]="showCloudFrontDiscountFields" [showS3DiscountFields]="showS3DiscountFields"
          [showRDSDiscountFields]="showRDSDiscountFields" (dataChange)="onDataChange($event)" [childs]="childs">
        </non_nha>
      </div>


      <div class="row" *ngIf="action !== 'update'">
        <label for="comment">Comment</label>
        <textarea id="comment" [(ngModel)]="mrr.comment" class="form-control"></textarea>
      </div>

      <div class="row" style="padding-left: 0px;">
        <label>MRR Period: </label>
        <input type="text" id="dataRangePicker" [(ngModel)]="dateRange" class="form-control" style="width: 300px"
          placeholder="Select date" [disabled]="action == 'update'" />
      </div>

      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button class="btn" (click)="processItemsSequentially()" style="
              background-color: #5864ff;
              color: white;
              text-transform: capitalize;
              margin: right 20px;
            ">
            {{ action == "add" ? "add" : action }}
          </button>
          <button class="btn btn-light" (click)="close(false)">Cancel</button>
        </div>
      </div>
    </div>
  </div>


<!-- Modal Template for Service Status -->
<div *ngIf="isModalVisible" class="modal-overlay">
  <div class="modal-content">
    <!-- <h3>Service Status</h3> -->
    <div *ngFor="let service of childs" class="service-status">
      <span>{{ service.name }}</span>
      <div class="status-icon">
        <div *ngIf="service.isLoading" class="circle loading">
          <!-- Adding a loader icon (can use a spinner or a dot for example) -->
          <span class="icon">⟳</span> <!-- You can replace this with a spinning icon -->
        </div>
        <div *ngIf="service.isSuccess" class="circle success">
          <span class="icon">✔</span>
        </div>
        <div *ngIf="service.isError" class="circle error">
          <span class="icon">✘</span>
        </div>
      </div>
    </div>
    <button [disabled]="!allCallsCompleted" (click)="closeModal()">Close</button>
  </div>
</div>




  <style>
    .margin_left_0 {
      margin-left: 0px;
    }

    legend {
      float: none;
      font-size: 1rem;
    }

    .legend-section {
      margin: 20px;
    }

    fieldset {
      border: 1px solid #ccc;
      margin-bottom: 20px;
      padding: 15px;
    }

    label {
      margin-right: 10px;
    }

    input[type="number"] {
      width: 150px;
      margin-left: 10px;
    }

    .alarm_screen label {
      padding: 0 !important;
    }

    .table-wrapper {
      display: flex;
      justify-content: center;
      /* Center horizontally */
      align-items: center;
      /* Center vertically */
      height: 100vh;
      /* Full viewport height */
      position: relative;
    }

    .table-container {
      max-height: 80%;
      /* Set max height for vertical scrolling */
      overflow-y: auto;
      /* Enable vertical scrolling */
      width: 80%;
      /* Adjust as needed for width */
      border: 1px solid #ddd;
      /* Optional border */
      background-color: #fff;
      /* Background for clarity */
      padding: 30px;
      position: relative;
      padding-bottom: 60px;
      display: flex;
      flex-direction: column;
    }



    .sticky-footer {
      position: sticky;
      bottom: 0;
      width: 100%;
      /* Ensure it takes the full width of the table container */
      background-color: #fff;
      /* Adjust background color if needed */
      padding: 10px;
      /* Add padding to match your design */
      /* box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); Optional: shadow for better visibility */
      z-index: 10;
      /* Ensure it stays on top */
      text-align: right;
      /* Align buttons to the right */
    }

    .table-responsive {
      max-height: 100%;
      /* Make it take full container height */
    }

    .popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-body {
  margin-top: 20px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.status {
  font-size: 18px;
}

.status.loading {
  color: orange;
}

.status.success {
  color: green;
}

.status.error {
  color: red;
}

.loader {
  border: 2px solid transparent;
  border-radius: 50%;
  border-top: 2px solid #007bff;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

.checkmark {
  font-size: 20px;
}

.error {
  font-size: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



/* Modal Overlay to cover entire screen */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content Box */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width:40%;
  text-align: center;
  position: relative;
}

h3 {
  margin-bottom: 20px;
}

.service-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.status-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

/* Modify the loading circle to be black */
.loading {
  background-color: black; /* Change to black */
  color: white; /* To ensure that the loader's icon (if any) is visible in white */
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.loading .icon {
  color: white;
  animation: spin 1s infinite linear; /* Add spin animation for loader */
}

/* Optional: Spin animation for the loader */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.success {
  background-color: green;
  color: white;
}

.error {
  background-color: red;
  color: white;
}

.icon {
  font-size: 20px;
}

/* button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
} */


  </style>
</div>