import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

import * as moment from 'moment';

declare let window: any;
declare let $: any;
@Component({
  selector: 'new-support-order',
  templateUrl: './new_support_order.component.html',
})
export class NewSupportOrder implements OnInit {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  planData: any = [];
  updateRI: any;
  delete: boolean = false;
  updateAction: any = 'update';

  accountId: any;
  regionId: any;
  currentMessage: any;
  unsavedRI: any = [];

  headers: any = [
    {
      id: 'mid',
      name: 'Master ID',
      sort: { sort: true, direction: 'Asc' },
      filter: true,
    },
    {
      id: 'companyName',
      name: 'Company Name',
      // sort: { sort: true, direction: 'Asc' },
      filter: true,
    },
    {
      id: 'lids',
      name: 'Child Accounts',
      click: 'viewDetails',
    },
    {
      id: 'percOrDisc',
      name: 'Rate Card Details',
      sort: { sort: true, direction: 'Asc' },
      filter: true,
    },
    {
      id: 'applyOn',
      name: 'Apply On',
      // sort: { sort: true, direction: 'Asc' },
      filter: true,
    },
    {
      id: 'ramp',
      name:'Enterprise On-Ramp',
    },
    {
      id: 'discount',
      name: 'Discount',
      sort: { sort: true, direction: 'Asc' },
      filter: true,
    },
    {
      id: 'startDate',
      name: 'Start Date',
      // sort: { sort: true, direction: 'Asc' },
      // filter: true,
    },
    {
      id: 'endDate',
      name: 'End Date',
      // sort: { sort: true, direction: 'Asc' },
      // filter: true,
    },
  ];

  funRef: any = {};
  deleteRI: any;
  unsavedUpdateId: number = -1;
  mid: any;
  indexdelete: any;
  showModal: any = false;
  modalData: any;
  selectedLids: any;

  constructor(
    public notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.funRef = { load: this.load };

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load() {
    this.notifier.loading(true);

    let data = { a: 'fetch' };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/sp-non-slab`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.planData = result.supportPlanModels;
    } else {
      this.planData = [];
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  updateTrigger(id: any) {
    this.updateAction = 'update';
    this.updateRI = id;
  }

  deleteUnsavedRI(id: number) {
    this.unsavedRI.splice(id, 1);
  }

  updateUnsavedRI(id: any, index: number) {
    this.updateAction = 'save';
    this.unsavedUpdateId = index;
    this.updateRI = id;
  }

  closeUpdateTrigger(event: any) {
    // console.log('closeUpdateTrigger', event);
    if (event) {
      this.load();
    }

    this.updateRI = null;
  }

  delete_selected(id: any) {
    this.delete = true;
    this.indexdelete = id;
  }

  async deleteTrigger(id: any) {
    this.notifier.loading(true);
    let data: any = {
      a: 'delete',
      mid: id.mid,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/sp-non-slab`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.load();
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.delete = false;
    this.notifier.loading(false);
  }

  closeDeleteTrigger(event: any) {
    this.deleteRI = null;
  }

  addPurchase() {
    this.updateAction = 'add';
    this.unsavedUpdateId = -1;
    this.updateRI = {
      mid: '',
      lids: [] as { id: number; name: string }[],
      percOrDisc: '',
      applyOn: '-',
      discount: '',
      startDate: '',
      endDate: '',
      ramp: 'no'
    };
  }

  openModal(lids: any[]) {
    this.showModal = true;
    this.selectedLids = lids;
  }

  closeModal() {
    this.showModal = false;
  }

  callFunction(param: any, id: any) {
    this.funRef[param](this, id);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
